























































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import breakpoints from '@/plugins/breakpoints';
import { Dictionaries } from '@/modules/genprox/modules/fund/modules/portfolio/store/types';
import moment from 'moment'

@Component({
  components: {
    SygniCircleButton, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniDatePicker, SygniSquareButton, SygniRectButton, SygniModal },
})
export default class ReportingFormTable extends SygniTable<any> {
  dictionaries!: Dictionaries;
  refresh: any = null;

  breakpoints = breakpoints;

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'name', sortable: false, class: 'text-left', label: 'Report Name'},
    { key: 'period', sortable: false, class: 'text-left period-col', label: 'Reporting Period'},
    { key: 'actions', sortable: false, class: 'actions', borderless: true, label: 'Actions'},
  ];

  tableItems: any = [
    {
      id: 1,
      name: 'Bank Transactions',
      action: 'bank-transactions',
      type: 'dateFrom',
      period: moment().utc().format('YYYY')
    },
    {
      id: 2,
      name: 'P&L Transactions',
      action: 'p-l-transactions',
      type: 'dateFrom',
      period: moment().utc().format('YYYY')
    },
    {
      id: 3,
      name: 'Portfolio & Capital',
      action: 'portfolio-capital',
      type: 'dateTo',
      period: moment().utc().format('YYYY')
    },
    {
      id: 4,
      name: 'All entries Transactions',
      action: 'all-entries-transactions',
      type: 'dateFrom',
      period: moment().utc().format('YYYY')
    },
    {
      id: 5,
      name: 'Receivables & Payables',
      action: 'receivables-payables',
    },
    {
      id: 6,
      name: 'Capital Rise',
      action: 'capital-rise-analytics-debt',
    },
    {
      id: 7,
      name: 'Brokers',
      action: 'broker',
    },
  ]
}

